import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpErrorResponse } from '@angular/common/http';
import {
  switchMap,
  filter,
  zip,
  take,
  of,
  delay,
  tap,
  catchError,
  map,
  throwError,
  mergeMap,
  from,
  merge,
  firstValueFrom,
  takeUntil,
  race,
  Observable,
  withLatestFrom,
  EMPTY,
} from 'rxjs';
import { samlSuccessRoute, samlTokenParameterName, APP_URL, SentryService, GoogleTagManagerService, CompanyStatus } from 'common-module';
import { NotificationModel, NotificationType } from 'notification-module';
import { NOT_SET } from '../constants';
import { RouterModel } from 'router-module';
import { WindowService } from 'global-module';
import { AuthService } from '../services/auth.service';
import { AuthModel } from './model';
import { routerRequestAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  UserCredential,
  sendEmailVerification,
  signOut,
  signInWithCustomToken,
  signInWithRedirect,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  browserPopupRedirectResolver,
sendPasswordResetEmail } from 'firebase/auth';
import { isUserFirstAndLastNameInputRequired } from 'shared-utils';
import { TeamsService } from '../services/teams.service';
import { FirebaseError } from 'firebase/app';
import { LoginType } from 'types/enums';
import { IUser } from 'types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  googleProvider = new GoogleAuthProvider().setCustomParameters({ prompt: 'select_account' });
  microsoftProvider = new OAuthProvider('microsoft.com').setCustomParameters({ prompt: 'select_account' });

  actions$ = inject(Actions);

  authService = inject(AuthService);
  windowService = inject(WindowService);
  authModel = inject(AuthModel);

  routerModel = inject(RouterModel);
  notificationModel = inject(NotificationModel);
  sentryErrorHandler = inject(SentryService);
  googleTagManager = inject(GoogleTagManagerService);
  teamsService = inject(TeamsService);

  appURL = inject<string>(APP_URL);

  init = createEffect(() =>
    this.actions$.pipe(
      ofType(routerRequestAction),
      take(1),
      map(({ payload }) => this.authModel.actions.create.authenticate({ initialPath: payload.event.url }))
    )
  );

  authenticate = createEffect(() =>
    this.authModel.actions.listen.authenticate$.pipe(
      switchMap(({ initialPath }) =>
        zip(this.routerModel.selectors.url$, this.routerModel.selectors.queryParams$, [{ initialPath }]).pipe(take(1))
      ),
      switchMap(([url, queryParams, payload]) =>
        zip(
          this.authService.getCorporateInfo().pipe(take(1),catchError((err: HttpErrorResponse) => [err])),
          this.authService.getUser().pipe(take(1), catchError((err: HttpErrorResponse) => [err])),
          this.authModel.idTokenResult$.pipe(take(1)),
          this.authModel.firebaseUser$.pipe(take(1))
        ).pipe(
          take(1),
          switchMap(([corporateInfoResponse, userResponse, idTokenResult, firebaseUser]) => {
            const isSamlRedirect = !!localStorage.getItem('saml');
            if (corporateInfoResponse instanceof HttpErrorResponse || userResponse instanceof HttpErrorResponse || !firebaseUser) {
              const isLoggedIn = !!idTokenResult?.token;
              url = payload?.initialPath || url;
              const isOnFreeTrialPage = url.includes('free-trial');
              const isOnSamlPage = url.includes('saml');
              const isOnMaintenancePage = url.includes('maintenance');
              const isOnLoginPage = url.includes('login');
              const isOnDefaultRoute = ['', '/'].includes(url);
              const hasSlackLoginData = !!localStorage.getItem('slack-login');
              const redirectUrl = hasSlackLoginData ? '/slack' : (queryParams?.['redirectUrl'] as string) || url || undefined;
              const newQueryParams = isOnLoginPage || isOnDefaultRoute ? {} : { redirectUrl };
              if (isSamlRedirect) localStorage.removeItem('saml');
              const navigationToLoginAction = this.routerModel.actions.create.navigate({
                commands: ['/login'],
                extras: { queryParamsHandling: 'merge', queryParams: newQueryParams },
              });

              const setEmptyUserAction = this.authModel.actions.create.setUser({ user: null });
              const errorNotificationAction = this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|error-corporate-info-failure:Cannot fetch corporate info`,
                notificationType: NotificationType.ERROR,
              });

              return from(
                isOnFreeTrialPage || isOnMaintenancePage || isOnSamlPage
                  ? [setEmptyUserAction]
                  : !isLoggedIn
                  ? [setEmptyUserAction, navigationToLoginAction]
                  : [setEmptyUserAction, navigationToLoginAction, errorNotificationAction]
              );
            }

            const isDeskbirdAdmin = idTokenResult?.claims?.['group'] === 'admin';
            const corporateInfo = corporateInfoResponse.data;

            const actions: Action[] = [
              this.authModel.actions.create.loginSuccess({
                user: userResponse,
                isDeskbirdAdmin,
                corporateInfo,
              }),
              this.authModel.actions.create.freeTrialData({
                freeTrialStartDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialStartDate : null,
                freeTrialEndDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialEndDate : null,
              }),
            ];

            const userNeedsToVerifyEmail = !firebaseUser.emailVerified;
            const userNameInputRequired = isUserFirstAndLastNameInputRequired(userResponse);
            const userProfilePictureRequired = userResponse.profileImage === null;
            const needsToVisitSignUpWizard = userNameInputRequired || userProfilePictureRequired;

            if (userNeedsToVerifyEmail || needsToVisitSignUpWizard) {
              this.authModel.actions.dispatch.checkEmail({ email: userResponse.email });
              return race(
                this.authModel.actions.listen.checkEmailSuccess$.pipe(map(() => true)),
                this.authModel.actions.listen.checkEmailFailure$.pipe(map(() => false))
              ).pipe(
                take(1),
                switchMap((result) => (result ? actions : throwError(() => new Error('Error fetching checkEmail'))))
              );
            }
            return actions;
          }),
          catchError((error: Error | FirebaseError) => {
            this.sentryErrorHandler.handleError(error);
            this.authModel.actions.dispatch.logout();

            return [this.authModel.actions.create.loginFailure({ error })];
          })
        )
      )
    )
  );

  redirectResult = createEffect(() =>
    this.authModel.actions.listen.authenticate$.pipe(
      switchMap(() =>
        this.authModel.selectors.user$.pipe(
          filter((user): user is IUser | null => user !== NOT_SET),
          take(1),
          switchMap((user) => (user ? zip(this.authModel.firebaseUser$, [user]).pipe(take(1)) : [[null, null, null]])),
          switchMap(([fireUser, user]) => {
            const samlProviderData = fireUser?.providerData.find((i) => i?.providerId.includes('saml'));
            const microsoftProviderData = fireUser?.providerData.find((i) => i?.providerId.includes('microsoft.com'));
            const googleProviderData = fireUser?.providerData.find((i) => i?.providerId.includes('google.com'));

            if (!user || [!!samlProviderData, !!microsoftProviderData, !!googleProviderData].every((i) => i === false)) return [];

            const isSamlRedirect = localStorage.getItem('saml');
            // check if it's a saml redirect and if yes navigate back to /saml route to complete process
            const actions: Action[] = [];
            if (isSamlRedirect) actions.push(this.authModel.actions.create.samlMobileNavigation());
            return actions;
          }),
          catchError((error: Error | FirebaseError) => {
            const notifications: Action[] = [];

            const genericLoginNotificationFailedNotification = this.notificationModel.actions.create.showNotification({
              data: $localize`:@@auth-module|error-login-failed:Login failed`,
              notificationType: NotificationType.ERROR,
            });
            notifications.push(genericLoginNotificationFailedNotification);

            this.sentryErrorHandler.handleError(error);
            return [this.authModel.actions.create.loginFailure({ error })];
          })
        )
      )
    )
  );

  samlMobilePageNavigation = createEffect(
    () =>
      this.authModel.actions.listen.samlMobileNavigation$.pipe(
        withLatestFrom(this.routerModel.selectors.queryParams$),
        filter(([, queryParams]) => {
          const isMobileEndScreen = !!((queryParams?.[samlTokenParameterName] as string) || null);
          // make sure again that it's a saml redirect callback
          const isSamlRedirect = localStorage.getItem('saml');
          return !!isSamlRedirect && !isMobileEndScreen;
        }),
        switchMap(() =>
          this.authModel.selectors.user$.pipe(
            filter((val): val is IUser => !!val && val !== NOT_SET),
            take(1)
          )
        ),
        switchMap((user) => {
          const getCustomMobileToken$ = this.authService.getFirebaseCustomAuthenticationToken();
          return getCustomMobileToken$.pipe(
            tap((customToken) => {
              const newHref = customToken
                ? this.appURL
                    .concat(samlSuccessRoute)
                    .concat(`?${samlTokenParameterName}=`)
                    .concat(customToken as any)
                    .concat('&userId=' + user.id)
                : this.appURL.concat(samlSuccessRoute);
              this.windowService.window.location.href = newHref;
            })
          );
        })
      ),
    { dispatch: false }
  );

  checkEmail = createEffect(() =>
    this.authModel.actions.listen.checkEmail$.pipe(
      switchMap(({ email }) =>
        this.authService.checkEmail(email).pipe(
          switchMap(({ data, success }) =>
            success
              ? [this.authModel.actions.create.checkEmailSuccess({ emailCheckResult: { ...data, email } })]
              : throwError(() => new Error('CHECK EMAIL FAILURE'))
          ),
          catchError((err: HttpErrorResponse | Error) => {
            this.sentryErrorHandler.handleError(err);
            const actions: Action[] = [this.authModel.actions.create.checkEmailFailure({ error: err, email })];
            if (!('status' in err) || err.status === 503) return actions;
            actions.push(
              this.notificationModel.actions.create.showNotification({
                data: $localize`:@@common|error-check-email:Error checking email`,
                notificationType: NotificationType.ERROR,
              })
            );
            return actions;
          })
        )
      )
    )
  );

  signInWithSamlRedirect = createEffect(() =>
    this.authModel.actions.listen.samlSignIn$.pipe(
      switchMap(({ provider }) => {
        const samlProvider = new SAMLAuthProvider(provider);
        return signInWithRedirect(this.authModel.auth, samlProvider, browserPopupRedirectResolver); // this will do a page redirect
      }),
      switchMap(() => {
        // we just dispatch success the rest of the authentication will be done by the authenticate
        // and redirectResult effects once we land back on the page
        return [this.authModel.actions.create.samlSignInSuccess()];
      }),
      catchError((error: Error) => {
        this.sentryErrorHandler.handleError(error);
        return [this.authModel.actions.create.samlSignInFailure({ error })];
      })
    )
  );

  fetchUserAndCorporateData = createEffect(() =>
    this.authModel.actions.listen.fetchUserAndCorporateInformation$.pipe(
      switchMap(({ dispatchLoginActions }) =>
        zip(this.authService.getCorporateInfo().pipe(take(1)), this.authService.getUser().pipe(take(1)), this.authModel.idTokenResult$.pipe(take(1))).pipe(
          switchMap(([corporateInfoResponse, user, idTokenResult]) => {
            const result: Action[] = [
              this.authModel.actions.create.fetchUserAndCorporateInformationSuccess({
                user,
                isDeskbirdAdmin: idTokenResult?.claims?.['group'] === 'admin',
                corporateInfo: corporateInfoResponse.data,
              }),
            ];

            if (dispatchLoginActions) {
              result.push(
                this.authModel.actions.create.loginSuccess({
                  user,
                  isDeskbirdAdmin: idTokenResult?.claims?.['group'] === 'admin',
                  corporateInfo: corporateInfoResponse.data,
                }),
                this.authModel.actions.create.freeTrialData({
                  freeTrialStartDate:
                    corporateInfoResponse.data.status === CompanyStatus.Trial ? corporateInfoResponse.data.trialStartDate : null,
                  freeTrialEndDate:
                    corporateInfoResponse.data.status === CompanyStatus.Trial ? corporateInfoResponse.data.trialEndDate : null,
                })
              );
            }

            return result;
          }),
          catchError((error: Error | FirebaseError) => {
            this.sentryErrorHandler.handleError(error);
            const result: Action[] = [this.authModel.actions.create.fetchUserAndCorporateInformationFailure({ error })];

            if (dispatchLoginActions) {
              const genericLoginNotificationFailedNotification = this.notificationModel.actions.create.showNotification({
                data: $localize`:@@auth-module|error-login-failed:Login failed`,
                notificationType: NotificationType.ERROR,
              });
              const loginFailureAction = this.authModel.actions.create.loginFailure({ error });
              result.push(genericLoginNotificationFailedNotification, loginFailureAction);
            }
            return result;
          })
        )
      )
    )
  );

  login = createEffect(() =>
    this.authModel.actions.listen.login$.pipe(
      map((payload): Observable<UserCredential | null> => {
        if (payload.loginType === LoginType.Google)
          return from(
            signInWithPopup(this.authModel.auth, this.googleProvider, browserPopupRedirectResolver)
          ) as unknown as Observable<UserCredential>;
        if (payload.loginType === LoginType.Microsoft) {
          return this.teamsService.initialized$.pipe(
              take(1),
              switchMap(teamsInitialized => {
                if (teamsInitialized) {
                  this.authModel.actions.dispatch.microsoftTeamsLogin({});
                  return EMPTY;
                } else {
                  return from(
                    signInWithPopup(this.authModel.auth, this.microsoftProvider, browserPopupRedirectResolver)
                  )
                }
              })
            );
        }
        if (payload.loginType === LoginType.Email)
          return from(
            signInWithEmailAndPassword(this.authModel.auth, payload.email, payload.password)
          ) as unknown as Observable<UserCredential>;
        if (payload.loginType !== LoginType.Saml) return of(null);
        const samlProvider = new SAMLAuthProvider(payload.providerId);
        return from(signInWithRedirect(this.authModel.auth, samlProvider, browserPopupRedirectResolver));
      }),
      switchMap((login$) =>
        login$.pipe(
          take(1),
          switchMap((result: any) => {
            if (result === null) return [];
            const actions: Action[] = [
              this.authModel.actions.create.fetchUserAndCorporateInformation({ dispatchLoginActions: true })
            ];

            if ( result.providerId === 'microsoft.com') {
              const credential = OAuthProvider.credentialFromResult(result);
              const accessToken = credential?.accessToken;
              
              actions.push(this.authModel.actions.create.syncMicrosoftProfilePicture({ firebaseId: result.user.uid, accessToken: accessToken!, email: result.user.email! }));
            }
              
            return actions;
          }),
          catchError((error: Error | FirebaseError) => {
            const notifications: Action[] = [];
            if ('code' in error) {
              // TODO: probably we need to add more notifications here for the firebase errors
              if (error.code === 'auth/popup-blocked') {
                const popupBlockedNotification = this.notificationModel.actions.create.showNotification({
                  data: $localize`:@@auth-module|error-login-popup-blocked:Popup blocked`,
                  notificationType: NotificationType.ERROR,
                });
                notifications.push(popupBlockedNotification);
              }
              if (error.code === 'auth/account-exists-with-different-credential') {
                const popupBlockedNotification = this.notificationModel.actions.create.showNotification({
                  data: $localize`:@@auth-module|error-login-account-exists-with-different-credential:Wrong login method for provided account`,
                  notificationType: NotificationType.ERROR,
                });
                notifications.push(popupBlockedNotification);
              }
            }

            if (notifications.length === 0) {
              const genericLoginNotificationFailedNotification = this.notificationModel.actions.create.showNotification({
                data: $localize`:@@auth-module|error-login-failed:Login failed`,
                notificationType: NotificationType.ERROR,
              });
              notifications.push(genericLoginNotificationFailedNotification);
            }

            this.sentryErrorHandler.handleError(error);
            return [this.authModel.actions.create.loginFailure({ error }), ...notifications];
          })
        )
      )
    )
  );

  register = createEffect(() =>
    this.authModel.actions.listen.register$.pipe(
      switchMap(({ email, password, firstName, lastName }) =>
        createUserWithEmailAndPassword(this.authModel.auth, email, password).then(({ user: fireUser }) => {
          if (!fireUser) throw new Error('Missing data');

          sendEmailVerification(fireUser).catch((error) => {
            this.notificationModel.actions.create.showNotification({
              data: $localize`: @@auth-module|error-email-verification:Error resending verification email`,
              notificationType: NotificationType.ERROR,
            });
          });
          return { fireUser, email, firstName, lastName };
        })
      ),
      switchMap(({ firstName, lastName }) =>
        this.authService.patchUser({ firstName, lastName }).pipe(
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [
              this.authModel.actions.create.patchUserFailure({ error }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|error-update-user:Profile update failed`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      ),
      tap(() => this.authModel.actions.dispatch.fetchUserAndCorporateInformation({ dispatchLoginActions: false })),
      switchMap(() =>
        race(
          this.authModel.actions.listen.fetchUserAndCorporateInformationSuccess$,
          this.authModel.actions.listen.fetchUserAndCorporateInformationFailure$.pipe(switchMap(({ error }) => throwError(() => error)))
        ).pipe(take(1))
      ),
      switchMap(({ user, corporateInfo, isDeskbirdAdmin }) => {
        return [this.authModel.actions.create.registerSuccess({ user, corporateInfo, isDeskbirdAdmin })];
      }),
      catchError((error) => {
        this.sentryErrorHandler.handleError(error);
        const registrationFailedNotification = this.notificationModel.actions.create.showNotification({
          data: $localize`: @@auth-module|register-failed:Registration failed`,
          notificationType: NotificationType.ERROR,
        });
        return [this.authModel.actions.create.registerFailure({ error }), registrationFailedNotification];
      })
    )
  );

  setProfileImage = createEffect(() =>
    this.authModel.actions.listen.setProfileImage$.pipe(
      switchMap(({ profileImage }) =>
        this.authService.patchUser({ avatarUrl: profileImage }).pipe(
          switchMap(() => []),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [
              this.authModel.actions.create.patchUserFailure({ error }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|error-update-user:Profile update failed`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      )
    )
  );

  loginWithMicrosoftTeams = createEffect(() =>
    this.authModel.actions.listen.microsoftTeamsLogin$.pipe(
      switchMap((data) =>
        this.teamsService.initialized$.pipe(
          filter((val) => !!val),
          take(1),
          map(() => data),
          switchMap((data) => {
            type GetAuthTokenResult = {
              token: string | null;
              message: string | null;
              success: boolean;
              data: typeof data;
            };

            const getAuthToken = new Promise<GetAuthTokenResult>((resolve) => {
              this.teamsService.authentication.getAuthToken({
                resources: ['https://web-staging.deskbird.app'],
                successCallback(token: string): void {
                  console.log('authentication.getAuthToken success', token);
                  resolve({ token, message: null, success: true, data });
                },
                failureCallback(message: string): void {
                  console.log('authentication.getAuthToken failure', message);
                  resolve({ token: null, message, success: false, data });
                },
              });
            });

            const op = getAuthToken.then((authTokenResponse) => {
              const microsoftToken = authTokenResponse?.token;
              if (!microsoftToken) return { error: new Error('Failed to get microsoft auth token') };
              return firstValueFrom(this.authService.authenticateWithMicrosoftToken(microsoftToken))
                .then((result) => signInWithCustomToken(this.authModel.auth, result.data.customToken)
                  .then((signInResult) => ({ error: null, result: signInResult, customToken: result.data.customToken, microsoftToken }))
                )
                .catch((error: Error) => ({ error }));
            });

            return from(op);
          }),
          switchMap((result) => {
            if (result.error !== null) {
              console.error('MS Teams login error: ', result.error);
              return throwError(() => result.error);
            }

            return [result];
          }),
          tap((login) => {
            this.authModel.actions.dispatch.fetchUserAndCorporateInformation({ dispatchLoginActions: false });
          }),
          switchMap(() =>
            race(
              this.authModel.actions.listen.fetchUserAndCorporateInformationSuccess$,
              this.authModel.actions.listen.fetchUserAndCorporateInformationFailure$.pipe(switchMap(({ error }) => throwError(() => error)))
            ).pipe(take(1))
          ),
          switchMap(({ user, corporateInfo, isDeskbirdAdmin }) => {
            const loginMicrosoftTeamsSuccess = this.authModel.actions.create.microsoftTeamsLoginSuccess({
              user,
              corporateInfo,
              isDeskbirdAdmin,
            });

            const freeTrialDataAction = this.authModel.actions.create.freeTrialData({
              freeTrialStartDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialStartDate : null,
              freeTrialEndDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialEndDate : null,
            });

            return [loginMicrosoftTeamsSuccess, freeTrialDataAction];
          }),
          catchError((error: Error | FirebaseError) => {
            this.sentryErrorHandler.handleError(error);
            let errorCode: string = '';

            if ('code' in error) errorCode = `(${error.code})`;
            const notification = {
              data: $localize`: @@auth-module|microsoft-sso-failure-with-error-code:Error signing in with provided microsoft account ${errorCode}`,
              notificationType: NotificationType.ERROR,
            };

            return [
              this.notificationModel.actions.create.showNotification(notification),
              this.authModel.actions.create.microsoftTeamsLoginFailure({ error, user: null }),
            ];
          })
        )
      )
    )
  );

  updateUser = createEffect(() =>
    this.authModel.actions.listen.updateUser$.pipe(
      mergeMap(({ updates, userId, noNotification }) =>
        this.authService.updateUser(updates).pipe(
          switchMap(({ data, success, message }) => {
            const actions: Action[] = [];
            const notification = success
              ? this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|success-update-user:Profile updated successfully`,
                  notificationType: NotificationType.SUCCESS,
                })
              : this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|error-update-user:Profile update failed`,
                  notificationType: NotificationType.ERROR,
                });

            const outcome = success
              ? this.authModel.actions.create.updateUserSuccess({ updates: data, userId })
              : this.authModel.actions.create.updateUserFailure({ error: new Error(message), updates: data });

            if (!noNotification) actions.push(notification);
            actions.push(outcome);

            return actions;
          }),
          catchError((error: Error) => {
            this.sentryErrorHandler.handleError(error);
            const actions: Action[] = [this.authModel.actions.create.updateUserFailure({ error, updates })];
            if (!noNotification)
              actions.push(
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|error-update-user:Profile update failed`,
                  notificationType: NotificationType.ERROR,
                })
              );
            return actions;
          })
        )
      )
    )
  );

  patchUser = createEffect(() =>
    this.authModel.actions.listen.patchUser$.pipe(
      switchMap(({ updates }) =>
        this.authService.patchUser(updates).pipe(map(() => this.authModel.actions.create.patchUserSuccess({ updates })))
      ),
      catchError((error) => {
        this.sentryErrorHandler.handleError(error);
        return [
          this.authModel.actions.create.patchUserFailure({ error }),
          this.notificationModel.actions.create.showNotification({
            data: $localize`: @@auth-module|error-update-user:Profile update failed`,
            notificationType: NotificationType.ERROR,
          }),
        ];
      })
    )
  );

  logout = createEffect(() =>
    this.authModel.actions.listen.logout$.pipe(
      switchMap(() => signOut(this.authModel.auth)),
      switchMap(() => {
        this.googleTagManager.resetAuthAndCompanyData();
        return merge(
          [this.authModel.actions.create.logoutSuccess()],
          of(this.routerModel.actions.create.navigate({ commands: ['/login'] })).pipe(delay(0))
        );
      }),
      catchError((error: Error) => {
        this.sentryErrorHandler.handleError(error);
        return [
          this.authModel.actions.create.loginFailure({ error }),
          this.notificationModel.actions.create.showNotification({
            data: $localize`: @@auth-module|error-logout:Logout failed`,
            notificationType: NotificationType.ERROR,
          }),
        ];
      })
    )
  );

  refetchUserData = createEffect(() =>
    this.authModel.actions.listen.refetchAuthData$.pipe(
      take(1),
      tap(() => {
        this.authModel.actions.dispatch.fetchUserAndCorporateInformation({ dispatchLoginActions: false });
      }),
      switchMap(() =>
        race(
          this.authModel.actions.listen.fetchUserAndCorporateInformationSuccess$,
          this.authModel.actions.listen.fetchUserAndCorporateInformationFailure$.pipe(switchMap(({ error }) => throwError(() => error)))
        ).pipe(take(1))
      ),
      switchMap(({ user, isDeskbirdAdmin, corporateInfo }) => [
        this.authModel.actions.create.refetchAuthDataSuccess({ isDeskbirdAdmin, user, corporateInfo: corporateInfo }),
        this.authModel.actions.create.freeTrialData({
          freeTrialStartDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialStartDate : null,
          freeTrialEndDate: corporateInfo.status === CompanyStatus.Trial ? corporateInfo.trialEndDate : null,
        }),
      ]),
      catchError((error: Error) => {
        this.sentryErrorHandler.handleError(error);
        return [this.authModel.actions.create.refetchAuthDataFailure({ error })];
      })
    )
  );

  loadPublicDomainsList = createEffect(() =>
    this.authModel.actions.listen.loadPublicDomains$.pipe(
      switchMap(() =>
        this.authService.loadPublicDomainsList().pipe(
          takeUntil(this.authModel.actions.listen.loadPublicDomainsCancel$),
          map((publicDomains) => this.authModel.actions.create.loadPublicDomainsSuccess({ publicDomains })),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [this.authModel.actions.create.loadPublicDomainsFailure({ error })];
          })
        )
      )
    )
  );

  resendVerificationEmail$ = createEffect(() =>
    this.authModel.actions.listen.resendVerificationEmail$.pipe(
      switchMap(() =>
        this.authModel.firebaseUser$.pipe(
          take(1),
          switchMap((fireUser) => {
            if (!fireUser) {
              return [
                this.authModel.actions.create.logout(),
                this.notificationModel.actions.create.showNotification({
                  data: $localize`: @@auth-module|user-not-found-error:User not found`,
                  notificationType: NotificationType.ERROR,
                }),
              ];
            }
            return from(sendEmailVerification(fireUser)).pipe(
              take(1),
              switchMap(() => {
                return [
                  this.authModel.actions.create.resendVerificationEmailSuccess(),
                  this.notificationModel.actions.create.showNotification({
                    data: $localize`: @@auth-module|success-email-verification:Verification email sent`,
                    notificationType: NotificationType.SUCCESS,
                  }),
                ];
              })
            );
          }),
          catchError((error) => {
            this.sentryErrorHandler.handleError(error);
            return [
              this.authModel.actions.create.resendVerificationEmailFailure({ error }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|error-email-verification:Error resending verification email`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      )
    )
  );

  syncMicrosoftProfilePicture = createEffect(() => 
    this.authModel.actions.listen.syncMicrosoftProfilePicture$.pipe(
      switchMap(({email, firebaseId, accessToken}) =>
        this.authService.syncMicrosoftProfilePicture(email, firebaseId, accessToken).pipe(
          switchMap(() => {
            return [this.authModel.actions.create.syncMicrosoftProfilePictureSuccess()];
          }),
          catchError((error) => {
            return [this.authModel.actions.create.syncMicrosoftProfilePictureFailure({ error }),
              this.notificationModel.actions.create.showNotification({
                data: $localize`: @@auth-module|error-microsoft-profile-picture-sync:Error synchronizing profile picture`,
                notificationType: NotificationType.ERROR,
              }),
            ];
          })
        )
      )
  ));

  passwordReset$ = createEffect(() =>
    this.authModel.actions.listen.passwordReset$.pipe(
      switchMap(({ email }) =>
          sendPasswordResetEmail(this.authModel.auth, email)
          .then(() => this.authModel.actions.dispatch.passwordResetSuccess())
          .catch((error) => {
            this.sentryErrorHandler.handleError(error);
            return this.authModel.actions.dispatch.passwordResetFailure({ error });
          })
      ),
      switchMap((result) => {
        if (result && 'error' in result) {
          return [
            result,
            this.notificationModel.actions.create.showNotification({
              data: $localize`: @@auth-module|error-password-reset:Error sending password reset email`,
              notificationType: NotificationType.ERROR,
            }),
          ];
        }
        return [
          result,
          this.notificationModel.actions.create.showNotification({
            data: $localize`: @@auth-module|success-reset-password-mail:Password reset email sent successfully`,
            notificationType: NotificationType.SUCCESS,
          }),
        ];
      })
    )
  );
}
