import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  createNgModule,
} from '@angular/core';
import { AuthModel, TeamsService } from 'auth-module';
import { GlobalModel, NoticeMessageService, WindowService } from 'global-module';
import { GlobalLoaderModel } from 'loader-module';
import { SlideOverService } from 'slide-over-module';
import { DialogContainerService } from 'shared';
import { RouterModel } from 'router-module';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  interval,
  map,
  Subscription,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import moment from 'moment';
import { NotificationModel, NotificationType } from 'notification-module';
import { ENVIRONMENT, Environment, isProductionEnvironment } from 'common-module';
import { LAZY_MODULES } from './constants';
import { combineLatestForFrame } from 'shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  sub = new Subscription();

  @ViewChild('freeTrialPeriod', { read: TemplateRef }) freeTrialPeriodNoticeMessage!: TemplateRef<any>;

  private isReady$$ = new BehaviorSubject<boolean>(false);
  isReady$ = this.isReady$$.asObservable().pipe(distinctUntilChanged());
  @ViewChildren('lazyModule', { read: ViewContainerRef }) lazyModuleVCRefs!: QueryList<ViewContainerRef>;

  user$ = this.authModel.user$;

  freeTrialEndDate$ = this.authModel.selectors.freeTrialEndDate$.pipe(distinctUntilChanged());

  showContent$ = combineLatestForFrame([
    this.authModel.isAuthenticating$.pipe(distinctUntilChanged()),
    this.isReady$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([isAuthenticating, isReady]) => {
      return isAuthenticating === false && isReady === true;
    })
  );

  notificationOffsetLeft$ = combineLatestForFrame([
    this.routerModel.isAdminAppEnv$.pipe(distinctUntilChanged()),
    this.authModel.user$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
  ]).pipe(
    map(([isAdmin, user]) => {
      const offset = !user ? '32px' : isAdmin ? '256px' : '136px';
      return offset;
    })
  );

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private authModel: AuthModel,
    private globalLoaderModel: GlobalLoaderModel,
    private routerModel: RouterModel,
    private injector: Injector,
    @Inject(LAZY_MODULES) private lazyModules: any[],
    cd: ChangeDetectorRef,
    notificationModel: NotificationModel,
    noticeMessageService: NoticeMessageService,
    slideOverService: SlideOverService,
    dialogContainerService: DialogContainerService,
    renderer: Renderer2,
    windowService: WindowService,
    globalModel: GlobalModel,
    teamsService: TeamsService
  ) {
    const isProduction = isProductionEnvironment(this.environment);
    const teamsUrl = isProduction ? 'web.deskbird.app' : 'app-staging.deskbird.com';

    if (windowService.window.location.hostname.includes(teamsUrl)) {
      teamsService.initialized$
        .pipe(debounceTime(500))
        .pipe(take(1))
        .subscribe((isTeamsContext) => {
          if (!isTeamsContext && isProduction) {
            windowService.window.location.href =
              'https://app.deskbird.com' + windowService.window.location.pathname + windowService.window.location.search;
          } else {
            this.isReady$$.next(true);
          }
        });
    } else {
      this.isReady$$.next(true);
    }

    this.sub.add(
      this.authModel.isAuthenticating$.pipe(distinctUntilChanged(), take(2)).subscribe((isAuthenticating) => {
        this.globalLoaderModel.actions.dispatch.showLoader({ visibility: isAuthenticating });
      })
    );

    // Handle the case in CLIENT when we don't have a company (for Admin it's handled inside the CompanyComponent)
    this.sub.add(
      globalModel.selectors.noCompanyConfigured$
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(routerModel.isClientAppEnv$),
          filter(([val, isClientAppEnv]) => !!val && isClientAppEnv)
        )
        .subscribe(() => {
          routerModel.actions.dispatch.navigateByUrl({ url: '/no-company' });
        })
    );

    this.sub.add(
      combineLatest([slideOverService.slideOverOpen$, dialogContainerService.openDialogs$.pipe(map((dialogs) => dialogs.length > 0))])
        .pipe(
          map(([isSlideOverOpen, hasOpenDialogs]) => isSlideOverOpen || hasOpenDialogs),
          distinctUntilChanged()
        )
        .subscribe((hideScroll): void => {
          if (hideScroll) {
            return void renderer.setStyle(windowService.window.document.body, 'overflow', 'hidden');
          }
          renderer.setStyle(windowService.window.document.body, 'overflow', 'auto');
        })
    );

    this.sub.add(
      this.authModel.selectors.freeTrialEndDate$.pipe(distinctUntilChanged()).subscribe((freeTrialEndDate): void => {
        if (freeTrialEndDate) {
          renderer.addClass(windowService.window.document.body, 'with-notice');
          return void noticeMessageService.setNoticeMessageTemplateRef(this.freeTrialPeriodNoticeMessage);
        }
        renderer.removeClass(windowService.window.document.body, 'with-notice');
        noticeMessageService.clearNoticeMessageTemplateRef();
      })
    );

    // Handle free trial expiry UI updated and system logged state
    this.sub.add(
      this.authModel.selectors.freeTrialEndDate$
        .pipe(
          distinctUntilChanged(),
          filter((val): val is string => !!val),
          switchMap((freeTrialEndDate) => {
            const timeMoment = moment(freeTrialEndDate).utc();
            const endOfMoment = timeMoment.clone().utc().endOf('day');
            const diffMs = moment().utc().diff(timeMoment, 'milliseconds');
            const diffDays = moment().utc().diff(endOfMoment.add(1, 'second'), 'day');
            if (diffMs >= 0) {
              return [[-1, freeTrialEndDate]] as const;
            }
            return interval(Math.abs(diffMs)).pipe(
              take(1),
              switchMap(() => {
                if (diffDays >= 0) {
                  return [[0, null]] as const;
                }
                return interval(
                  24 * 3600000 // wait 24 hours and emit
                ).pipe(
                  take(Math.abs(diffDays)) // take as many days are left
                );
              }),
              switchMap((n) => this.authModel.selectors.freeTrialEndDate$.pipe(map((freeTrialEndDate) => [n, freeTrialEndDate] as const)))
            );
          })
        )
        .subscribe(([n, freeTrialEndDate]): void => {
          if (freeTrialEndDate !== null) {
            const timeMoment = moment(freeTrialEndDate).utc();
            const diffMs = moment().utc().diff(timeMoment, 'milliseconds');
            if (typeof n === 'number' && diffMs < 0 && n >= 0) {
              // Trigger CD so we can update the free trial template with the new days count
              return void cd.detectChanges();
            }
          }
          // If trial has expired logout and show notification
          this.authModel.actions.dispatch.logout();
          notificationModel.actions.dispatch.showNotification({
            data: $localize`:@@deskbird-app|free-trial-expired:Your free trial has expired!`,
            notificationType: NotificationType.WARNING,
          });
        })
    );
  }

  ngAfterViewInit(): void {
    this.lazyModuleVCRefs.forEach((vc, index) => {
      const lazyModule = this.lazyModules[index];
      const moduleRef = createNgModule(lazyModule.module, this.injector);
      const { changeDetectorRef } = vc.createComponent(lazyModule.component, { ngModuleRef: moduleRef });
      changeDetectorRef.detectChanges();
    });
  }

  fromNow(dateTimeInUtc: string | null): string {
    const timeMoment = moment(dateTimeInUtc).utc();
    let diffDays = moment().utc().diff(timeMoment, 'days');
    if (diffDays === 0) {
      return $localize`:@@deskbird-app|free-trial-expiry|today:today`;
    }
    return $localize`:@@deskbird-app|free-trial-expiry|in-days:in ${Math.abs(diffDays)} days`;
  }

  logoutHandler(): void {
    this.authModel.actions.dispatch.logout();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
