import '@angular/localize/init';

export * from './lib/confirmation/confirmation.component';
export * from './lib/confirmation.module';
export * from './lib/+store/model';
export * from './lib/providers';
export { CONFIRMATION_DIALOG_MAP } from './lib/constants';
export {
  IConfirmationConfig,
  IConfirmationDialogMap,
  IConfirmationDialogComponentMap,
  IConfirmationComponent,
  IGenericConfirmation
} from './lib/interfaces';
export * from './lib/generic-configs';