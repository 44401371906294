import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { redirectRouteFactory } from 'common-module';
import { ErrorUnexpectedComponent, OfflineComponent, MaintenanceComponent } from 'global-module';
import { AuthenticateActivate, AuthenticateLoad, AuthorizeActivate, AuthorizeLoad } from 'auth-module';
import { ApplicationModule } from 'common-module';
import { UserRole } from 'types';
import { DefaultComponentActivate, DefaultProfileActivate } from './guards';

export const routes: Routes = [
  redirectRouteFactory('', '/default', { absoluteRedirect: true }),
  {
    path: 'default',
    canActivate: [DefaultComponentActivate],
    children: [],
  },
  {
    path: 'error',
    component: ErrorUnexpectedComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'offline',
    component: OfflineComponent,
  },
  {
    path: 'admin',
    canLoad: [AuthenticateLoad, AuthorizeLoad],
    canActivate: [AuthenticateActivate, AuthorizeActivate],
    data: {
      userRoles: [UserRole.ADMIN, UserRole.OFFICE_ADMIN, UserRole.MANAGER, UserRole.GROUP_MANAGER],
    },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'planning',
    canLoad: [AuthenticateLoad, AuthorizeLoad],
    canActivate: [AuthenticateActivate, AuthorizeActivate],
    data: {
      appModuleRestrictions: ApplicationModule.Planning,
    },
    loadChildren: () => import('planning-module').then((m) => m.PlanningModule),
  },
  // NOTE: NOT BEING USED FOR A WHILE (probably we need to remove the whole logic)
  // {
  //   path: 'free-trial',
  //   canLoad: [AuthenticateLoad],
  //   canActivate: [AuthenticateActivate],
  //   data: {
  //     publicOnly: true,
  //   },
  //   loadChildren: () => import('free-trial-module').then((m) => m.FreeTrialModule),
  // },
  {
    path: 'profile/:profileId',
    canActivate: [DefaultProfileActivate],
    children: [],
  },
  {
    path: '',
    loadChildren: () =>
      import('office-module').then((m) =>
        m.OfficeModuleForClient.lazyLoadedWithRoutes([
          {
            path: 'bookings',
            canLoad: [AuthenticateLoad, AuthorizeLoad],
            canActivate: [AuthenticateActivate, AuthorizeActivate],
            data: {
              appModuleRestrictions: ApplicationModule.Booking,
            },
            loadChildren: () => import('booking-module').then((m) => m.BookingModuleForClient),
          },
        ])
      ),
  },
];

export const AppRoutingModule = RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false });
